import {useEffect, useState} from 'react'

import useIsBrowser from 'hooks/useIsBrowser'

const url = `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`

const itemsQuery = `
  items {
    name
    publicationDate
    documentType {
      name
    }
    file {
      url
      fileName
      contentType
    }
  }
`

const request = graphqlQuery => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    query: graphqlQuery,
  }),
})

const retrieveAll = async (hook, locale, documentsIDs) => {
  await fetch(
    url,
    request(`
      query {
        documentCollection(order: publicationDate_DESC, locale: ${JSON.stringify(
          locale,
        )}, where:{sys: {id_in: ${JSON.stringify(documentsIDs)}}}) {
          ${itemsQuery}
        }
      }
    `),
  )
    .then(response => response.json())
    .then(response => hook(response.data.documentCollection.items))
}

const contentfulGraphQlFiltering = async (locale, tags, hook, documentsIDs) => {
  await fetch(
    url,
    request(`
        query {
        documentCollection(order: publicationDate_DESC, locale: ${JSON.stringify(
          locale,
        )}, where: {sys: {id_in: ${JSON.stringify(
      documentsIDs,
    )}}, documentType: {name_in: ${JSON.stringify(tags)}}}) 
        {
            ${itemsQuery}
          }
        }
    `),
  )
    .then(response => response.json())
    .then(response => hook(response.data.documentCollection.items))
}

const useFinancialPagesFilter = (nodeLocale, currentTag, countryItemsIds) => {
  const [itemsToRender, setItemsToRender] = useState([])
  const noFilterSelected =
    useIsBrowser &&
    (window.location.hash === '' ||
      window.location.hash === '#/' ||
      currentTag.length === 0)

  useEffect(() => {
    if (noFilterSelected) {
      retrieveAll(setItemsToRender, nodeLocale, countryItemsIds)
    } else {
      setTimeout(() => {
        contentfulGraphQlFiltering(
          nodeLocale,
          currentTag,
          setItemsToRender,
          countryItemsIds,
        )
      }, 100)
    }
  }, [useIsBrowser && window.location.hash && currentTag])

  return itemsToRender
}

export default useFinancialPagesFilter
