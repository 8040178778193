import * as R from 'ramda'
import {graphql} from 'gatsby'
import React from 'react'

import {downloadPagePropTypes} from 'helpers/propTypes'
import FinancialPosts from 'components/AboutUs/FinancialPages'
import Hero from 'components/UI/Hero'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const DownloadPage = ({pageContext, data, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const {dataProtectionDocuments} = countryData

  const {pathname} = location

  const allPages = R.pathOr(null, ['allContentfulDownloadPage', 'nodes'], data)

  const currentPage = R.pathOr(
    null,
    [0],
    R.filter(e => pathname.includes(e.slug), allPages),
  )

  const isDataProtectionPage = currentPage.slug === 'data-protection'

  const {
    byTypeLabel,
    downloadCta,
    metaDescription,
    metaTitle,
    surtitle,
    searchLabel,
    shortDescription: {shortDescription},
    name,
    imageOrVideo,
    documents,
  } = currentPage

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <Hero
          hat={surtitle}
          title={name}
          description={shortDescription}
          titleType="title"
          media={imageOrVideo}
        />
      </Section>
      <FinancialPosts
        byTypeLabel={byTypeLabel}
        downloadCta={downloadCta}
        currentDocuments={
          isDataProtectionPage && dataProtectionDocuments
            ? dataProtectionDocuments
            : documents
        }
        searchLabel={searchLabel}
        pageContext={pageContext}
        location={location}
      />
    </>
  )
}

DownloadPage.propTypes = downloadPagePropTypes

DownloadPage.defaultProps = {
  data: {},
  pageContext: {},
}

export const pageQuery = graphql`
  query templateDownloadPage(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulDownloadPage(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        name
        slug
        byTypeLabel
        downloadCta
        documents {
          name
          id
          contentful_id
          createdAt
          documentType {
            id
            name
          }
          file {
            file {
              url
              fileName
              contentType
            }
          }
        }
        metaDescription {
          metaDescription
        }
        metaTitle
        surtitle
        pageTitle
        searchLabel
        shortDescription {
          shortDescription
        }
        imageOrVideo {
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
      dataProtectionDocuments {
        name
        id
        contentful_id
        createdAt
        documentType {
          id
          name
        }
        file {
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`

export default DownloadPage
